import {
	FragmentResult,
	ValueTypes,
	ZeusSelect,
} from '@goodlok/sdk/generated/content_role_customer'
import { AddressFragment } from './AddressFragment'
import { ImageFragment } from './ImageFragment'

export function OrderInListFragment(customerId: string) {
	return ZeusSelect<ValueTypes['Order']>()({
		id: true,
		seq: true,
		createdAt: true,
		fulfilledAt: true,
		delivery: [
			{},
			{
				expectedAt: true,
				deliveredAt: true,
				address: [{}, AddressFragment()],
				method: [
					{},
					{
						name: true,
						requireDeliveryAddress: true,
					},
				],
				window: true,
			},
		],
		billingAddress: [
			{},
			{
				addressLine1: true,
				city: true,
			},
		],
		summary: [
			{},
			{
				itemsQuantity: true,
				totalPriceCents: true,
				undiscountedPriceCents: true,
			},
		],
		payments: [
			{ orderBy: [{ createdAt: 'asc' }] },
			{
				id: true,
				method: [{}, { type: true, name: true }],
				walletType: true,
				state: true,
				invoice: [{}, { createdAt: true, fakturoidId: true, publicUrl: true, legacyUrl: true }],
				publicReceiptUrl: true,
			},
		],
		discounts: [
			{},
			{
				type: true,
				discountCents: true,
			},
		],
		items: [
			{},
			{
				id: true,
				quantity: true,
				product: [
					{},
					{
						id: true,
						name: true,
						image: [{}, ImageFragment()],
						priceCents: true,
						customerPricesByCustomer: [
							{ by: { customer: { id: customerId } } },
							{
								priceCents: true,
							},
						],
					},
				],
			},
		],
		state: true,
	})
}

export type OrderInListResult = FragmentResult<'Order', typeof OrderInListFragment>
