import { useMirrorLoading } from '@goodlok/ui'
import { useQuery } from '@tanstack/react-query'
import { OrderInListFragment } from '../data/OrderInListFragment'
import { customerZeus } from '../data/Zeus/client/customer'
import { useBusinessCustomerId } from '../hooks/useCustomer'

const ITEMS_PER_PAGE = 20

export function useListOrders(page: number) {
	const customerId = useBusinessCustomerId()

	const query = useQuery(
		['orders', customerId, page],
		async () => {
			if (!customerId) {
				throw new Error('unknown customer id')
			}
			const upcomingOrdersResult = await customerZeus('query')({
				listOrder: [
					{
						filter: {
							state: { in: ['waitingForPayment', 'processing', 'dispatched'] },
							customer: customerId ? { id: { eq: customerId } } : undefined,
						},
						orderBy: [
							{ fulfilledAt: 'desc' },
							{ delivery: { deliveredAt: 'desc' } },
							{ delivery: { expectedAt: 'desc' } },
						],
					},
					OrderInListFragment(customerId),
				],
			})

			const fulfilledOrdersResult = await customerZeus('query')({
				paginateOrder: [
					{
						filter: {
							state: { eq: 'fulfilled' },
							customer: customerId ? { id: { eq: customerId } } : undefined,
						},
						orderBy: [
							{ fulfilledAt: 'desc' },
							{ delivery: { deliveredAt: 'desc' } },
							{ delivery: { expectedAt: 'desc' } },
							{ createdAt: 'desc' },
						],
						first: ITEMS_PER_PAGE,
						skip: (page - 1) * ITEMS_PER_PAGE,
					},
					{
						pageInfo: { totalCount: true },
						edges: { node: OrderInListFragment(customerId) },
					},
				],
			})
			return {
				upcoming: {
					items: upcomingOrdersResult.listOrder,
				},
				fulfilled: {
					pageCount: Math.ceil(
						fulfilledOrdersResult.paginateOrder.pageInfo.totalCount / ITEMS_PER_PAGE
					),
					items: fulfilledOrdersResult.paginateOrder.edges.map((edge) => edge.node),
				},
			}
		},
		{
			enabled: Boolean(customerId),
		}
	)
	useMirrorLoading(query.isFetching)

	return query
}
