import { DetailedList, Icon } from '@goodlok/react-sdk'
import { useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import type { FunctionComponent } from 'react'
import { PageTitle } from '../../app/components/PageTitle'
import { useListPeople } from '../../app/hooks/useListPeople'
import styles from './PeoplePage.module.sass'
import { Spacer } from './Spacer'

export const PeoplePage: FunctionComponent = () => {
	const peopleQuery = useListPeople()
	const translate = useTranslate()

	return (
		<>
			<PageTitle>{translate('people.title')}</PageTitle>
			<Spacer initial={24} />
			{peopleQuery.data && (
				<DetailedList
					itemsLayout="shortTitle"
					items={peopleQuery.data.map((person) => {
						return {
							title: person.meta?.fallbackName ?? person.id,
							uniqueIdentifier: person.id,
							image: (
								<div className={styles.itemImage}>
									<Icon name="friendsOff" />
								</div>
							),
						}
					})}
				/>
			)}
		</>
	)
}
