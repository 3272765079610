import { Button, Modal, useGoodlokAuth } from '@goodlok/react-sdk'
import { notify } from '@goodlok/ui'
import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useState } from 'react'
import type { AddressResult } from '../data/AddressFragment'
import { customerZeus } from '../data/Zeus/client/customer'
import type { AddressFormInputData } from '../forms/AddressFormInput'
import { useBusinessCustomerId } from '../hooks/useCustomer'
import style from './Address.module.sass'
import { AddressForm } from './AddressForm'
import { Spacer } from './Spacer'

export interface AddressProps {
	address: AddressResult
	onChange: () => void
	isDefaultDeliveryAddress?: boolean
}

export const Address: FunctionComponent<AddressProps> = ({
	address,
	onChange,
	isDefaultDeliveryAddress,
}) => {
	const auth = useGoodlokAuth()

	const { id, name, fullName, addressLine1, addressLine2, city, postalCode } = address
	const [isModalOpen, setIsModalOpen] = useState(false)
	const businessCustomerId = useBusinessCustomerId()

	const updateMutation = useMutation(
		async (validatedData: AddressFormInputData) => {
			return auth.zeus
				.orders('mutation')({
					editAddress: [
						{
							addressId: id,
							data: {
								raw: validatedData.raw,
								email: validatedData.email,
								phone: validatedData.phone,
								name: validatedData.name,
								fullName: validatedData.fullName,
								setIsDefaultDeliveryAddress: validatedData.setIsDefaultDeliveryAddress,
							},
						},
						{
							ok: true,
							errorMessage: true,
						},
					],
				})
				.then((data) => data)
		},
		{
			onSuccess: (data) => {
				if (data.editAddress.ok) {
					onChange()
				} else {
					alert(data.editAddress.errorMessage)
				}
				setIsModalOpen(false)
			},
		}
	)

	const deleteMutation = useMutation(
		async () => {
			return customerZeus('mutation')({
				updateCustomer: [
					{
						by: { id: businessCustomerId },
						data: {
							addresses: [
								{
									update: {
										by: { id: id },
										data: {
											deletedAt: 'now',
										},
									},
								},
							],
							defaultDeliveryAddress:
								isDefaultDeliveryAddress || false ? { disconnect: true } : undefined,
						},
					},
					{
						ok: true,
						errorMessage: true,
					},
				],
			}).then((data) => data)
		},
		{
			onSuccess: (data) => {
				if (data.updateCustomer.ok) {
					onChange()
				} else {
					alert(data.updateCustomer.errorMessage)
				}
				setIsModalOpen(false)
			},
		}
	)

	useEffect(() => {
		if (updateMutation.isSuccess) {
			notify('Úspěšně upraveno', 'success')
		}
	}, [updateMutation.isSuccess])

	useEffect(() => {
		if (deleteMutation.isSuccess) {
			notify('Adresa byla úspěšně smazána', 'success')
		}
	}, [deleteMutation.isSuccess])

	return (
		<>
			<address className={style.wrapper}>
				<div className={style.content}>
					<div className={style.name}>{name}</div>
					<div>{fullName}</div>
					<div>{addressLine1}</div>
					<div>{addressLine2}</div>
					<div>
						{city} {postalCode}
					</div>
					<Spacer initial={20} />
					<div className={style.buttons}>
						<Button type="button" variant="primary" onClick={() => setIsModalOpen(true)}>
							Upravit
						</Button>
						<Button type="button" variant="danger" onClick={() => deleteMutation.mutate()}>
							Odstranit
						</Button>
					</div>
				</div>
				<div className={style.tags}>
					{isDefaultDeliveryAddress && <div className={style.tag}>Doručovací</div>}
					{/* {isDefaultBillingAddress && <div className={style.tag}>Fakturační</div>} */}
				</div>
			</address>

			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => {
					setIsModalOpen(false)
				}}>
				<div className={style.addressFormWrapper}>
					<AddressForm
						action="edit"
						editedAddress={{
							name: address.name || '',
							fullName: address.fullName || '',
							raw: address.raw || '',
							email: address.email || '',
							phone: address.phone || '',
							setIsDefaultDeliveryAddress: isDefaultDeliveryAddress || false,
						}}
						title={'Upravit adresu'}
						onSubmit={(validatedData) => {
							updateMutation.mutate(validatedData)
						}}
					/>
				</div>
			</Modal>
		</>
	)
}
