import {
	CollapsibleCard,
	OrderDetails,
	OrderTitle,
	StateLabel,
	WalletTypeIcon,
	Button,
} from '@goodlok/react-sdk'
import { FormattedCents } from '@goodlok/ui'
import { Fragment, FunctionComponent } from 'react'
import type { OrderInListResult } from '../data/OrderInListFragment'
import styles from './OrdersList.module.sass'

export type OrdersListProps = {
	items: OrderInListResult[]
}

export const OrdersList: FunctionComponent<OrdersListProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			{items.map((order) => {
				return (
					<CollapsibleCard
						key={order.id}
						isOpen={false}
						title={
							<OrderTitle
								productsCount={order.summary?.itemsQuantity}
								date={
									order.fulfilledAt ||
									order.delivery?.deliveredAt ||
									order.delivery?.expectedAt ||
									order.createdAt
								}
								state={order.state}
							/>
						}
						headerAdditionalInfo={
							<>
								{order.state !== 'waitingForPayment' ? (
									<div className={styles.payments}>
										{order.payments.map((item) => (
											<Fragment key={item.id}>
												<StateLabel
													variant="neutral"
													startIcon={<WalletTypeIcon walletType={item.walletType} />}>
													<div>
														{item.invoice ? (
															<a
																href={item.invoice.publicUrl ?? item.invoice.legacyUrl}
																target="_blank"
																rel="noreferrer"
																onClick={(event) => {
																	event.stopPropagation()
																}}>
																{item.method?.name}
															</a>
														) : (
															<>{!item.walletType && item.method?.name}</>
														)}
													</div>
												</StateLabel>
											</Fragment>
										))}
										<div className={styles.price}>
											{order.discounts.map((discount) => {
												return <>{discount.type === 'Credits' ? <>Credits</> : null}</>
											})}
											{order.summary && (
												<>
													{/* @TODO might behave unexpectadly if there are multiple payments for one order */}
													{order.summary.totalPriceCents === 0 ? (
														<FormattedCents cents={order.summary.undiscountedPriceCents} />
													) : (
														<FormattedCents cents={order.summary.totalPriceCents} />
													)}
												</>
											)}
										</div>
									</div>
								) : undefined}
							</>
						}>
						<OrderDetails order={order} />
						{order.payments.length === 1 &&
							order.payments[0].method?.type === 'stripe' &&
							order.payments[0].state === 'approved' && (
								<Button
									type="link"
									href={`${process.env.BACKEND_URL}/vat-receipt/${order.id}`}
									size="medium"
									variant="primary"
									outline>
									Stáhnout daňový doklad
								</Button>
							)}
					</CollapsibleCard>
				)
			})}
		</div>
	)
}
