import { useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import { FormattedCreditCents } from '@goodlok/ui'
import { Fragment, FunctionComponent } from 'react'
import { useCustomerInfo } from '../hooks/useCustomer'
import { useListAddresses } from '../hooks/useListAddresses'
import { Address } from './Address'
import { PageTitle } from './PageTitle'
import { SectionHeader } from './SectionHeader'
import { Spacer } from './Spacer'

export const SettingsPage: FunctionComponent = () => {
	const translate = useTranslate()
	const addresses = useListAddresses()
	const customerInfo = useCustomerInfo()

	const deliveryAddresses = addresses.data?.addresses ?? []
	// const billingSubjects = addresses.data?.billingSubjects ?? []
	return (
		<>
			<PageTitle>{translate('settings.title')}</PageTitle>

			{customerInfo.remainingCredits ? (
				<>
					<SectionHeader title={translate('settings.credits.title')} />
					{translate('settings.credits.remaining')}{' '}
					<FormattedCreditCents cents={customerInfo.remainingCredits} />
				</>
			) : null}

			<SectionHeader title={translate('settings.addresses')} />
			{deliveryAddresses.map((address, index) => {
				return (
					<Fragment key={address.id}>
						<Address
							address={address}
							onChange={() => addresses.refetch()}
							isDefaultDeliveryAddress={address.defaultDeliveryAddressOfCustomer !== null}
						/>
						{index < deliveryAddresses.length && <Spacer initial={24} />}
					</Fragment>
				)
			})}
			<SectionHeader title={translate('settings.businessSubjects')} />
			{/* {billingSubjects.map((address, index) => {
				return (
					<Fragment key={address.id}>
						<Address
							address={address}
							onChange={() => addresses.refetch()}
							// isDefaultDeliveryAddress={address.id === addresses.data?.defaultDeliveryAddress?.id}
						/>
						{index < deliveryAddresses.length && <Spacer initial={24} />}
					</Fragment>
				)
			})} */}
		</>
	)
}
