import { useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import { Pagination } from '@goodlok/ui'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import type { GoodlokOrderStates } from '../../../../meta/index'
import { PageTitle } from '../../app/components/PageTitle'
import { SectionHeader } from '../../app/components/SectionHeader'
import { Spacer } from '../../app/components/Spacer'
import { useListOrders } from '../hooks/useListOrders'
import { OrdersList } from './OrdersList'

export const orderStatesWithLabels: {
	[key in (typeof GoodlokOrderStates)[number]]: string
} = {
	created: 'Vytvořeno',
	waitingForPayment: 'Čeká na platbu',
	processing: 'Připravováno',
	dispatched: 'Vysláno',
	fulfilled: 'Dokončeno',
	canceled: 'Zrušeno',
}

export const HistoryPage: FunctionComponent = () => {
	const translate = useTranslate()
	const router = useRouter()
	const currentPage = (typeof router.query.p === 'string' ? parseInt(router.query.p, 10) : 1) || 1
	const orders = useListOrders(currentPage)

	return (
		<>
			{orders.data && (
				<>
					<PageTitle>{translate('orderHistory.title')}</PageTitle>
					{orders.data.upcoming && orders.data.upcoming.items.length > 0 && (
						<>
							<SectionHeader title={translate('orderHistory.upcomingOrders')} />
							<OrdersList items={orders.data.upcoming.items} />
						</>
					)}
					{orders.data.fulfilled && orders.data.fulfilled.items.length > 0 && (
						<>
							<SectionHeader title={translate('orderHistory.fulfilledOrders')} />
							<OrdersList items={orders.data.fulfilled.items} />
							<Spacer initial={12} />
							{orders.data.fulfilled.pageCount > 1 && (
								<Pagination
									currentPage={currentPage}
									pagesCount={orders.data.fulfilled.pageCount}
									pageUrlGenerator={(p) => ({
										pathname: router.pathname,
										query: { ...router.query, p },
									})}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	)
}
