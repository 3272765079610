import { getTranslations } from '@goodlok/react-sdk/src/translations/getTranslations'
import { TranslationsProvider } from '@goodlok/react-sdk/src/translations/Translations'
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { useRouter } from 'next/router'
import { HistoryPage } from '../app/components/HistoryPage'
import { Layout } from '../app/components/Layout'
import { OrderPage } from '../app/components/OrderPage'
import { PeoplePage } from '../app/components/PeoplePage'
import { SettingsPage } from '../app/components/SettingsPage'
import {
	useBusinessCategories,
	useCustomerBusinessCategory,
} from '../app/hooks/useBusinessCategories'
import { useBusinessCustomerId } from '../app/hooks/useCustomer'
import { routes } from '../app/utils/routes'

export type PageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function Page({ locale, translations }: PageProps) {
	const router = useRouter()

	const path = router.asPath.split('?')[0]

	const businessCustomerId = useBusinessCustomerId()
	const businessCategories = useBusinessCategories(locale)
	const customerBusinessCategory = useCustomerBusinessCategory(locale)

	return (
		<TranslationsProvider translations={translations} locale={locale}>
			<Layout>
				{path === routes.home ? (
					<>
						{businessCategories.data &&
							businessCustomerId &&
							!customerBusinessCategory.isLoading && (
								<OrderPage
									businessCategories={businessCategories.data}
									defaultBusinessCategory={
										customerBusinessCategory.data ? customerBusinessCategory.data : undefined
									}
								/>
							)}
					</>
				) : path === routes.people ? (
					<PeoplePage />
				) : path === routes.settings ? (
					<SettingsPage />
				) : path === routes.history ? (
					<HistoryPage />
				) : (
					<>Nenalezeno</>
				)}
			</Layout>
		</TranslationsProvider>
	)
}

// @TODO: use static props
export const getServerSideProps = async (context: GetServerSidePropsContext) => {
	const locale = context.locale ?? 'cs'
	const translations = await getTranslations('b2b_app', locale)

	// @TODO: handle 404

	return {
		props: {
			locale,
			translations,
		},
	}
}
